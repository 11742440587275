import React, { useEffect, useState } from 'react';
import HeroSlider, { Overlay, Slide, Nav } from "hero-slider";
import "./slider.css";
import MenuIcon from '@mui/icons-material/Menu';
import { MENULATERAL } from '../menulat/menulat';
import logo_curiel from "../../images/Logo_Blanco_SinFondo.png";
import logo_curiel_b from "../../images/Logo_curiel_black.png";

const slide1 = "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/1.webp";
const slide2  = "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/2.webp";
const slide3  = "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/3.webp";
const slide4  = "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/4.webp";
const slide5  = "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/5.webp";
const slide6  = "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/6.webp";
const slide7  = "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/7.webp";
const slide8  = "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/8.webp";
const slide9  = "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/9.webp";
const slide10 =  "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/10.webp";
const slide11 =  "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/11.webp";
const slide12 =  "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/12.webp";
const slide13 =  "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/13.webp";
const slide14 =  "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/HOME/14.webp";


export const SLIDER = (props) => {
    const [loaded, setLoaded] = useState(0);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setLoaded((prevProgress) => prevProgress + 1);
      }, 100); 
  
      if (loaded >= 100) {
        clearInterval(timer);
        setLoaded(0);
      }
      return () => {
        clearInterval(timer);
      };
    }, [loaded]);
    
    
    const [menuVisible, setMenuVisible] = useState(false);


    const handleMouseEnter = (event) => {
      if (event.clientX >= window.innerWidth - 5) {
        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    };
  return (
    <>
      <div className='container-slider' id='home' onMouseMove={handleMouseEnter}>
        <div className='pestaña-des' onClick={()=>setMenuVisible(true)}>
          <MenuIcon></MenuIcon>
        </div>
        
        <HeroSlider
            autoplay
            height="100vh"
            
            controller={{
              initialSlide: 1,
              slidingDuration: 1000,
              slidingDelay: 0,
              onSliding: (nextSlide) =>
                console.debug("onSliding(nextSlide): ", nextSlide, setLoaded(0)),
              onBeforeSliding: (previousSlide, nextSlide) =>
                console.debug(
                  "onBeforeSliding(previousSlide, nextSlide): ",
                  previousSlide,
                  nextSlide,
                  setLoaded(0)
                ),
              onAfterSliding: (nextSlide) =>
                console.debug("onAfterSliding(nextSlide): ", nextSlide, setLoaded(0))
            }}
        >
        <Slide
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide1,
            backgroundAnimation: 'fade'     
          }}
        >
          <Overlay>
            <div className='container-text'>
              <img src={logo_curiel} className='logo-ca'></img>
            </div>
          </Overlay>

        </Slide>
        <Slide
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide2,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide3,
            backgroundAnimation: 'fade',
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide4,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>      
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide5,
            backgroundAnimation: 'fade' 
          }}
        >
        <Overlay>
            <div className='container-text'>
              <img src={logo_curiel_b} className='logo-ca'></img>
            </div>
        </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide6,
            backgroundAnimation: 'fade' 
          }}
        >
        <Overlay>
            <div className='container-text'>
              <img src={logo_curiel_b} className='logo-ca'></img>
            </div>
        </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide7,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide8,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide9,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide10,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide11,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide12,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide13,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide14,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
      <Nav></Nav>
      </HeroSlider>      
      </div>

        <div className={`menu ${menuVisible ? 'visible' : ''}`}>
          <MENULATERAL ingles={props.ingles} setingles={props.setingles}></MENULATERAL>
        </div>    
    </>
    
  )
}
