export function data_images_gallery(){
    var data = [
        {
            name: "PROYECTO NS",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/63.webp",
            fotos: [
               { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/02.webp" },
               { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/03.webp" },
               { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/05.webp" },
               { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/06.webp" },
               { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/07.webp" },
               { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/08.webp" },
               { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/09.webp" },
               { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/10.webp" },
               { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/11.webp" },
               { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/12.webp" },
               { id: "11", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/18.webp" },
               { id: "12", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/19.webp" },
               { id: "13", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/21.webp" },
               { id: "14", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/23.webp" },
               { id: "15", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/24.webp" },
               { id: "16", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/25.webp" },
               { id: "17", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/26.webp" },
               { id: "18", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/27.webp" },
               { id: "19", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/30.webp" },
               { id: "20", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/38.webp" },
               { id: "21", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/40.webp" },
               { id: "22", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/45.webp" },
               { id: "23", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/46.webp" },
               { id: "24", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/47.webp" },
               { id: "25", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/48.webp" },
               { id: "26", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/49.webp" },
               { id: "27", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/52.webp" },
               { id: "28", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/53.webp" },
               { id: "29", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/55.webp" },
               { id: "30", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/56.webp" },
               { id: "31", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/57.webp" },
               { id: "32", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/59.webp" },
               { id: "33", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/60.webp" },
               { id: "34", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/63.webp" },
               { id: "35", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/64.webp" },
               { id: "36", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/67.webp" },
               { id: "37", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/68.webp" },
               { id: "38", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+NS/69.webp" }
           ]
        },
        {
            name: "PROYECTO AR",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T01.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T01.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T02.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T02.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T04.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T05.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T06.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T07.webp" },
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T08.webp" },
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T09.webp" },
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T10.webp" },
                { id: "11", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T11.webp" },
                { id: "12", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T12.webp" },
                { id: "13", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T13.webp" },
                { id: "14", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T14.webp" },
                { id: "15", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T15.webp" },
                { id: "16", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T16.webp" },
                { id: "17", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T17.webp" },
                { id: "18", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T19.webp" },
                { id: "19", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T20.webp" },
                { id: "20", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T21.webp" },
                { id: "21", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AR/T23.webp" }
            ]
        },
        {

            //26
            name: "PROYECTO BL",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/26.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/05.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/22.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/07.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/15.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/12.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/21.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/09.webp" },
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/16.webp" },
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/25.webp" },
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+BL/26.webp" },
            ]
        },
        {
            name: "PROYECTO CC",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CC/001.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CC/001.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CC/02C5C.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CC/002.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CC/01C5C.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CC/003.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CC/03C5C.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CC/05C5C.webp" },
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CC/06C5C.webp"}
            ]
        },
        {
            name: "PROYECTO CP",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP002.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP001.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP004.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP011.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP005.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP003.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP006.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP007.webp" },
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP008.webp" },
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP009.webp" },
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CP/CP010.webp" }
            ]
        },
        {
            name: "PROYECTO CR",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CR/22.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CR/22.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CR/111.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CR/333.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CR/444.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CR/555.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+CR/666.webp" }
            ]
        },
        {
            name: "PROYECTO GL",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/09.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/09.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/10.webp" }, 
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/13.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/16.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/17.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/18.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/21.webp" },
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/22.webp" },
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/23.webp" },
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/24.webp" },
                { id: "11", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/25.webp" },
                { id: "12", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/27.webp" },
                { id: "13", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/28.webp" },
                { id: "14", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/29.webp" },
                { id: "15", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/30.webp" },
                { id: "16", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/31.webp" },
                { id: "17", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/32.webp" },
                { id: "18", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/33.webp" },
                { id: "19", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/34.webp" },
                { id: "20", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/35.webp" },
                { id: "21", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/36.webp" },
                { id: "22", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/37.webp" },
                { id: "23", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/38.webp" },
                { id: "24", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/39.webp" },
                { id: "25", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/40.webp" },
                { id: "26", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/41.webp" },
                { id: "27", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/42.webp" },
                { id: "28", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GL/44.webp" }    
            ]
        },
        {
            name: "PROYECTO GW",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw1.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw1.webp"},
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw2.webp"},
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw3.webp"},
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw4.webp"},
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw5.webp"},
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw6.webp"},
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw7.webp"},
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw8.webp"},
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw9.webp"},
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw10.webp" },
                { id: "11", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw11.webp" },
                { id: "12", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+GW/gw12.webp" }
            ]
        },
        {
            name: "PROYECTO JZ",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+JZ/01JAZ.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+JZ/01JAZ.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+JZ/02JAZ.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+JZ/03JAZ.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+JZ/04JAZ.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+JZ/05JAZ.webp" }
            ]
        },
        {
            name: "PROYECTO LB",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/L01.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/L01.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/L02.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/L03.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/L04.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/L05.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/L06.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/L07.webp" },
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/L08.webp" },
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/MUEBLETV11.webp" },
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/MUEBLETV22.webp" },
                { id: "11", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LB/MUEBLETV33.webp" },
            ]
        },
        {
            name: "PROYECTO LC",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LC/01.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LC/01.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LC/02.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LC/03.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LC/04.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LC/05.webp" }
            ]
        },
        {
            name: "PROYECTO LD",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3943.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3691.webp"},
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3700.webp"},
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3708.webp"},
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3741.webp"},
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3792.webp"},
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3813.bp.webp"},
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3871.webp"},
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3895.webp"},
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3918.webp"},
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3933.webp" },
                { id: "11", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3943.webp" },
                { id: "12", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+LD/IMG_3946.webp" },
            ]
        },
        {
            name: "PROYECTO MD",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MD/221901_Curiel_01.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MD/221901_Curiel_01.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MD/221901_Curiel_02.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MD/221901_Curiel_03.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MD/221901_Curiel_04.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MD/221901_Curiel_05.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MD/221901_Curiel_06.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MD/221901_Curiel_08.webp" },
            ]
        },
        {
            name: "PROYECTO MG",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MG/MAG008.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MG/MAG001.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MG/MAG002.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MG/MAG003.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MG/MAG004.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MG/MAG006.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+MG/MAG008.webp" },
            ]
        },
       
        {
            name: "PROYECTO RB",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb1.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb1.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb2.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb3.webp"},
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb4.webp"},
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb5.webp"},
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb6.webp"},
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb7.webp"},
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb8.webp"},
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb9.webp"},
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb10.webp" },
                { id: "11", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb11.webp" },
                { id: "12", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb12.webp" },
                { id: "13", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb13.webp" },
                { id: "14", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb14.webp" },
                { id: "15", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb15.webp" },
                { id: "16", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb16.webp" },
                { id: "17", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb17.webp" },
                { id: "18", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb18.webp" },
                { id: "19", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb19.webp" },
                { id: "20", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb20.webp" },
                { id: "21", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RB/rb21.webp" }
            ]
        },
        {
            name: "Proyecto RG",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/01REG.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/01REG.webp"},
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/001REG.webp"},
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/002REG.webp"},
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/03REG.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/003REG.webp"},
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/004REG.webp"},
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/005REG.webp"},
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/04REG.webp" },
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/05REG.webp" },
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/06REG.webp" },
                { id: "11", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/006REG.webp"},
                { id: "12", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/007REG.webp"},
                { id: "13", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/07REG.webp" },
                { id: "14", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/008REG.webp"},
                { id: "15", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/08REG.webp" },
                { id: "16", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/09REG.webp" },
                { id: "17", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RG/10REG.webp7.webp" }
            ]
        },
        {
            name: "Proyecto RM",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RM/R2.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RM/RM.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RM/R2.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RM/RM3.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RM/RM4.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RM/RM5.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RM/RM6.webp" }
            ]
        },
        {
            name: "PROYECTO RS",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO033.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO10.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO12.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO13.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/L011.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO011.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO022.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO033.webp"},
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO044.webp" },
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO066.webp" },
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+RS/LO077.webp" },
            ]
        },
        {
            name: "PROYECTO SB",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+SB/s1.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+SB/s1.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+SB/s2.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+SB/s3.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+SB/s4.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+SB/s5.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+SB/s6.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+SB/s8.webp" },
            ]
        },
        {
            name: "PROYECTO TM",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t1.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t1.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t2.webp " },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t3.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t4.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t5.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t6.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t7.webp" },
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t8.webp" },
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+TM/t9.webp" },
            ]
        },
        {
            name:"PROYECTO VS",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS001.webp",
            fotos: [
                {id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS001.webp" },
                {id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS002.webp" },
                {id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS003.webp" },
                {id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS004.webp" },
                {id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS005.webp" },
                {id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS006.webp" },
                {id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS007.webp" },
                {id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS008.webp" },
                {id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+VS/VS009.webp" },
            ]
        },
        {
            name: "Proyecto AC",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AC/01A.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AC/01A.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AC/02A.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AC/03A.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AC/04A.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AC/05A.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyecto+AC/06A.webp" }
            ]
        },
        {
            name: "PROYECTOS CR",
            portada: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/15.webp",
            fotos: [
                { id: "1", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/01.webp" },
                { id: "2", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/02.webp" },
                { id: "3", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/04.webp" },
                { id: "4", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/05.webp" },
                { id: "5", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/08.webp" },
                { id: "6", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/11.webp" },
                { id: "7", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/13.webp" },
                { id: "8", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/14.webp" },
                { id: "9", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/16.webp" },
                { id: "10", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/18.webp" },
                { id: "11", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/19.webp" },
                { id: "12", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/20.webp" },
                { id: "13", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/21.webp" },
                { id: "14", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/27.webp" },
                { id: "15", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/28.webp" },
                { id: "16", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/31.webp" },
                { id: "17", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/33.webp" },
                { id: "18", url: "https://gallery-curiel-webp.s3.us-east-1.amazonaws.com/Proyectos+CR/34.webp" }
            ]
        }

    ];
    
    return data;
}